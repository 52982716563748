.map-section-container {
  display: block;
  padding-bottom: 50px;
}

.map-section-container h2 {
  font-weight: 600;
  font-size: 24px;
  margin: 40px;
  text-align: center;
}

.map-container {
  max-width: 50rem;
  height: 30rem;
  margin: 0 auto;
  border-radius: 20px;
}
